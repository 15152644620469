<template>
    <div class="carousel_container index-container">
        <div class="top">
            <div class="title">
                {{ "正在进行的竞赛" | language }}
            </div>
            <div class="more click-op" @click="goToMore">
                <!-- v-if="info.news.length>=6" -->
                <div class="line"></div>
                <div class="text mr-l1">{{ "查看更多" | language }}</div>
                <Icon type="ios-arrow-forward" :size="16" />
            </div>
        </div>
        <swiper
            class="mySwiper mr-t3"
            ref="mySwiper"
            v-if="lists.length"
            :options="swiperOption"
            @slideChange="handleChange"
        >
            <swiper-slide v-for="item in lists" :key="item._id">
                <div class="swiper-back" @click="goTo(item)">
                    <div class="img-container">
                        <img class="_img" :src="item.cover.src" alt="" />
                    </div>
                    <div class="_title">{{ item.title }}</div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="pagination_wrap" v-if="lists.length">
            <div class="btn prev" @click="handlePre"></div>
            <div class="pagination">
                <div
                    class="pagination-index"
                    v-for="(item, i) in lists"
                    @click="handleClick(i)"
                    :class="{ active: index == i }"
                    :key="i"
                ></div>
            </div>
            <div class="btn next" @click="handleNext"></div>
        </div>
        <div class="flex-auto"></div>
    </div>
</template>

<script>
import { HttpUtil } from "../../assets/util/http";
import { menu } from "../../assets/util/menu";
export default {
    data() {
        return {
            lists: [],
            index: 0,
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 10000,
                },
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        goToMore() {
            this.$router.push("/base/information/competition-activities");
        },
        goTo(item) {
            this.$router.push(
                `/base/information/competition-activities/detail/${item._id}`
            );
        },
        handleClick(index) {
            this.$nextTick(() => {
                this.index = index;
                this.$refs.mySwiper.swiper.slideToLoop(this.index);
            });
        },
        handleChange() {
            this.index = this.$refs.mySwiper.swiper.realIndex;
        },
        handlePre() {
            this.index -= 1;
            if (this.index < 0) {
                this.index = this.lists.length - 1;
            }
            this.$refs.mySwiper.swiper.slideToLoop(this.index);
        },
        handleNext() {
            this.index += 1;
            if (this.index == this.lists.length) {
                this.index = 0;
            }
            this.$refs.mySwiper.swiper.slideToLoop(this.index);
        },

        async fetchData() {
            let res = await HttpUtil.get(
                `/information/competition?type=ongoing&year=${new Date().getFullYear()}&pageSize=3&pageNum=${0}`
            );
            this.lists = res.result.filter(
                (item) => item.cover && item.cover.src
            );
        },
    },
};
</script>
<style lang="less" scoped>
.pagination_wrap {
    // z-index: 999;

    display: flex;
    align-items: center;
    // position: absolute;
    // left: 50%;

    // bottom: -30px;
    // transform: translateX(-50%);
    .pagination {
        display: flex;
        align-items: center;
        .pagination-index {
            width: 12px;
            height: 12px;
            background: #00305b;
            display: inline-block;
            border-radius: 100%;
            opacity: 1;
            margin: 0 20px;
        }
        .active {
            background: #3b95fc;
        }
    }
    .btn {
        width: 16px;
        height: 16px;
        margin: 0 16px;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
    .prev {
        background-image: url("http://cdn.sjtuair.com/77c09370-64e1-11ea-838c-8d8294c3577c.svg");
    }
    .next {
        background-image: url("http://cdn.sjtuair.com/7b125680-64e1-11ea-838c-8d8294c3577c.svg");
    }
    &:hover {
        cursor: pointer;
    }
}

.carousel_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 800px;
    .top {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .title {
            opacity: 0.9;
            font-size: 30px;
            line-height: 32px;
            font-weight: 600;
        }
        .more {
            display: flex;
            align-items: center;
            .line {
                width: 4px;
                height: 12px;
                background-color: #f55e5a;
            }
            .text {
                margin-right: 5px;
                opacity: 0.9;
                font-size: 16px;
                line-height: 29px;
            }
        }
    }
    .mySwiper {
        margin-bottom: 60px;
        width: 1040px;
        height: 481px;
        .swiper-back {
            .img-container {
                width: 1040px;
                height: 430px;
                overflow: hidden;
                ._img {
                    width: 1040px;
                    height: 430px;
                    object-fit: cover;
                    transition: all linear 0.3s;
                    &:hover {
                        transform: scale(1.05);
                        cursor: pointer;
                    }
                }
            }

            ._title {
                margin-top: 20px;
                font-size: 20px;
                text-align: center;
                line-height: 29px;
                transition: all linear 0.3s;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    // .swiper-container {
    //   width: 827px;
    //   height: 429px;
    //   background: red;
    //   // max-height: 880px;
    // }
    .flex-auto {
        flex: 1;
    }
}
</style>
